import React from "react";
import "../styles/ResearchSection.css";
import ResearchItem from "./ResearchItem";

const ResearchSection = () => {
  return (
    <div className="research-section">
      <h2>Research</h2>
      <div className="research-list">
        <ResearchItem
          title="Distillation of Diffusion Features for Semantic Correspondence"
          image="DistillDIFT.png"
          authors={[
            "Frank Fundel",
            "Johannes Schusterbauer",
            "Tao Hu",
            "Björn Ommer",
          ]}
          highlightedAuthor={0}
          journal="WACV 2025"
          description="We demonstrate how to distill two large vision foundation models into a smaller, high-accuracy model with lower computational cost."
          highlighted
        />
        <ResearchItem
          title="Automatic bat call classification using transformer networks"
          image="BAT.png"
          authors={["Frank Fundel", "Daniel A. Braun", "Sebastian Gottwald"]}
          highlightedAuthor={0}
          journal="Ecological Informatics"
          description="ConvNet-Transformer hybrid model enables sequence-based bat call classification, surpassing previous single call approaches.."
          arxivUrl="https://arxiv.org/abs/2309.11218"
          codeUrl="https://github.com/FrankFundel/BAT"
        />
      </div>
    </div>
  );
};

export default ResearchSection;
