import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import NewsSection from "../components/NewsSection";
import ResearchSection from "../components/ResearchSection";
import "../styles/Home.css"; // General styles

function Home() {
  return (
    <div className="Home">
      <Header />
      <HeroSection />
      <NewsSection />
      <ResearchSection />
      <div className="footer">
        <p>Copyright © 2024, Frank Fundel</p>
      </div>
    </div>
  );
}

export default Home;
