import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/ResearchSection.css";

class ResearchItem extends Component {
  render() {
    const {
      title,
      image,
      authors,
      highlightedAuthor,
      journal,
      description,
      projectUrl,
      codeUrl,
      arxivUrl,
      highlighted,
    } = this.props;

    return (
      <div className={`research-item ${highlighted ? "highlighted" : ""}`}>
        {image && (
          <a href={arxivUrl} target="_blank" rel="noopener noreferrer">
            <img src={image} alt={title} className="thumbnail" />
          </a>
        )}
        <div className="content">
          <h3>
            <a href={arxivUrl} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </h3>
          <p className="authors">
            {authors
              .map((author, index) => (
                <span
                  key={index}
                  style={{
                    textDecoration:
                      index === highlightedAuthor ? "underline" : "none",
                  }}
                >
                  {author}
                </span>
              ))
              .reduce((prev, curr) => [prev, ", ", curr])}
          </p>
          {journal && <p className="journal">{journal}</p>}
          {description && <p className="description">{description}</p>}
          <ul className="links">
            {projectUrl && (
              <>
                <li>
                  <a href={projectUrl}>Project Page</a>
                </li>
                <li>/</li>
              </>
            )}
            {codeUrl && (
              <>
                <li>
                  <a href={codeUrl}>Code</a>
                </li>
                <li>/</li>
              </>
            )}
            {arxivUrl && (
              <li>
                <a href={arxivUrl}>arXiv</a>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

ResearchItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
  highlightedAuthor: PropTypes.number,
  journal: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  projectUrl: PropTypes.string.isRequired,
  codeUrl: PropTypes.string.isRequired,
  arxivUrl: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
};

export default ResearchItem;
